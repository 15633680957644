.dateRangePicker {
  height: 42px;

  .DateRangePickerInput {
    border-radius: 5px;
    border: 1px solid #859eb9;
    overflow: hidden;
  }

  &.focused {
    .DateRangePickerInput {
      outline: 2px solid #002c5e;
    }

    .DateInput input {
      color: #002c5e;
    }
  }

  .DateInput {
    width: auto;
    text-align: center;

    input {
      height: 40px;
      max-width: 200px;
      padding: 0;
      border-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }

  .CalendarMonth_caption {
    padding-top: 28px;
    line-height: 32px;
    padding-bottom: 50px;

    strong {
      font-size: 16px;
    }
  }

  .DayPicker_weekHeader {
    top: 78px;
  }

  .CalendarDay {
    border: none;

    &[aria-disabled='true'] {
      background-color: #fff;
      color: #d7d7d7;
    }

    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end {
      position: relative;
      border-radius: 50% 0 0 50%;
      background: #ebeef5;

      > .day-wrapper {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        border-radius: 50%;
        background-color: #2965d6;
      }
    }

    &.CalendarDay__selected_end {
      border-radius: 0 50% 50% 0;
    }

    &.CalendarDay__selected_span {
      color: #8b8b8b;
      background-color: #ebeef5;
    }

    &.CalendarDay__hovered_span {
      background-color: #ebeef5;

      > div {
        position: relative;
        z-index: 2;
      }

      & + :not(.CalendarDay__hovered_span) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -48px;
          top: 0;
          width: 48px;
          height: 47px;
          background: radial-gradient(circle at center, #ebeef5, #ebeef5 68%, transparent calc(68% + 1px)),
            linear-gradient(to right, #ebeef5, #ebeef5 50%, #fff 50%);
          z-index: 1;
        }
      }
    }
  }
}

.navIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 32px;
  background-color: #ebeef5;

  &.prev {
    top: 28px;
    left: 34px;
  }

  &.next {
    top: 28px;
    right: 34px;
  }
}
