.side {
  min-width: 200px;
  background: #002C5E;
  .logo {
    width: 73px;
    height: 80px;
    background: url('../../../assets/images/logo.svg') center / contain no-repeat;
    margin: 53px auto 62px auto;
  }
}
