.menu {
  font-size: 16px;
  color: #859EB9;

}

.menuItem {
  &.active{
    background-color: #2D6CDF;
    .title {
      color: #ffffff;
    }
  }
  &.open{
    .title {
      color: #ffffff;
    }
  }
  .title {
    height: 38px;
    display: flex;
    align-items: center;
    cursor: default;
    box-sizing: border-box;
    padding-left: 50px;
    transition: 0.3s;
    &:hover {
      color: #ffffff;
      background-color: #2D6CDF;
    }
  }
  .icon {
    width: 18px;
    height: 18px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 16px;
  }
  .merchant {
    background-image: url('../../../assets//icons/vector.png');
    background-size: 14px 17px;
  }
  .order {
    background-image: url('../../../assets//icons/order.png');
    background-repeat: no-repeat;
    margin-bottom: 8px;
  }
}
.menuChildren {
  font-size: 14px;
  color: #859EB9;
  line-height: 20px;
}

.menuChildrenItem {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  padding-left: 84px;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: #2D6CDF;
  }
  &.cActive {
    color: #ffffff;
  }
}
